<template>
  <div
    class="min-h-screen h-full bg-white md:bg-[linear-gradient(143.08deg,#F7FBFF_21.45%,#EEF7FF_78.55%)]"
  >
    <TsHeader data-testid="header" />
    <Head>
      <Title v-if="!productStore.is_product_loading">{{
        productStore.builder_product &&
        productStore.builder_product.data &&
        productStore.builder_product.data.seoMetaTags &&
        productStore.builder_product.data.seoMetaTags.title
          ? productStore.builder_product.data.seoMetaTags.title
          : productStore.product ? productStore.product.name: ''
      }}</Title>
    </Head>
    <TsSection append-class="mb-0 pb-12">
      <div>
        <TsBreadcrumb root-class="pt-2 mb-6 lg:my-4" :data="breadcrumb_data" />
        <slot />
      </div>
    </TsSection>

    <TsModalSaveListFormV2 v-model:visible="savelistStore.modal_savelist_from_visible">
    </TsModalSaveListFormV2>
    <TsFooter data-testid="footer" />
  </div>
  <template v-if="isMobile">
    <TsDrawer
      v-model:visible="productStore.subscribe_notification_modal_visible"
      data-testid="subscribe-notification-modal"
      position="bottom"
      append-class="bg-transparent overflow-hidden min-h-48"
    >
      <TsModalOutOfStockSubscription
        @close="productStore.subscribe_notification_modal_visible = false"
        @subscribe="productStore.subscribe_notification_modal_visible = false"
      />
    </TsDrawer>
  </template>

  <template v-if="isDesktop">
    <TsModal
      v-model:visible="productStore.subscribe_notification_modal_visible"
      data-testid="subscribe-notification-modal"
      append-class="max-w-[450px]"
    >
      <template #modal-content>
        <TsModalOutOfStockSubscription
          @close="productStore.subscribe_notification_modal_visible = false"
          @subscribe="productStore.subscribe_notification_modal_visible = false"
        />
      </template>
    </TsModal>
  </template>
  <template v-if="isDesktop">
    <TsModal
      v-model:visible="productStore.subscription_successful_modal_visible"
      data-testid="subscription-successful"
      append-class="max-w-[450px]"
    >
      <template #modal-content>
        <TsModalSubscriptionConfirmation
          :productName="productStore.notify_product_name"
          :email="productStore.notify_user_email"
          @close="productStore.subscription_successful_modal_visible = false"
        />
      </template>
    </TsModal>
  </template>
  <template v-if="isDesktop">
    <TsModal
      v-model:visible="trolleyStore.already_subscribed_modal_visible"
      data-testid="already-subscribed"
      append-class="max-w-[450px]"
    >
      <template #modal-content>
        <TsModalSubscriptionConfirmation
          variant="alreadySubscribed"
          @close="trolleyStore.already_subscribed_modal_visible = false"
        />
      </template>
    </TsModal>
  </template>
  <!-- Nearby stock search modal and actions -->
  <TsFindStockNearbyActions v-if="productStore.product" :product="productStore.product" data-testid="PDP-nearby-stock-finder" />
</template>

<script setup lang="ts">
const productStore = useProductStore();
const savelistStore = useSavelistStore();
const trolleyStore = useTrolleyStore();
const breadcrumb_data = computed(() => {
  if (!productStore.taxonomies.length) return [];
  const breadcrumb_links = [
    {
      text: "Home",
      link: "/",
    },
    ...productStore.taxonomies,
    {
      text:
        productStore.builder_product &&
        productStore.builder_product.data &&
        productStore.builder_product.data.seoMetaTags &&
        productStore.builder_product.data.seoMetaTags.title
          ? productStore.builder_product.data.seoMetaTags.title
          : productStore.product?.name,
    },
  ];

  return breadcrumb_links;
});

const { isMobile, isDesktop } = useDevice();
</script>
